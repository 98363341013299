export default {
	data() {
		return {
			fenceTypeList: [
				{ label: "圆形", value: 1 },
				{ label: "矩形", value: 2 },
				{ label: "多边形", value: 3 }
			],
			messageTypeList: [
				{ label: "车辆驶入报警", value: 1 },
				{ label: "车辆驶出报警", value: 2 }
			],
			statusList: [
				{ label: "启用", value: 1 },
				{ label: "禁用", value: 2 }
			],
			fenceTypeMap: { 1: "圆形", 2: "矩形", 3: "多边形" },
			statusMap: { 1: "启用", 2: "禁用" }
		};
	}
};
