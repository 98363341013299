<template>
	<div class="eletric-fence-container">
		<jy-query ref="checkForm" :model="formData">
			<jy-query-item label="围栏名称:" prop="fenceName">
				<el-input placeholder="请输入电子围栏名称" v-model="formData.fenceName"></el-input>
			</jy-query-item>
			<jy-query-item label="围栏类别:" prop="fenceType">
				<el-select v-model="formData.fenceType" placeholder="请选择围栏类别">
					<el-option v-for="item in fenceTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="生效状态:" prop="status">
				<el-select v-model="formData.status" placeholder="请选择生效状态">
					<el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm">重置</el-button>
			</template>
			<template slot="operate">
				<el-button type="primary" @click="createElectricFence">新增</el-button>
			</template>
		</jy-query>
		<jy-table max-height="595" :data="lists">
			<jy-table-column type="index" width="60" label="序号" align="center"></jy-table-column>
			<jy-table-column prop="fenceName" min-width="60" label="电子围栏名称"></jy-table-column>
			<jy-table-column prop="fenceType" min-width="60" label="围栏类别">
				<template slot-scope="scope">
					{{ fenceTypeMap[scope.row.fenceType] }}
				</template>
			</jy-table-column>
			<jy-table-column prop="orgName" min-width="60" label="所属机构"></jy-table-column>
			<jy-table-column prop="status" min-width="60" label="生效状态">
				<template slot-scope="scope">
					{{ statusMap[scope.row.status] }}
				</template>
			</jy-table-column>
			<jy-table-column prop="createTime" min-width="60" label="创建时间"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="220"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageInfo.pageNum"
			:page-size="pageInfo.pageSize"
			:total="pageInfo.total"
		>
		</jy-pagination>
		<electric-fence-detail ref="electric" @update="addOrUpdate" />
	</div>
</template>
<script>
import electricFenceMixin from "@/common/js/electricFence.mixin.js";
import ElectricFenceDetail from "./electricFenceComponent/detail";
export default {
	name: "",
	mixins: [electricFenceMixin],
	components: { ElectricFenceDetail },
	data() {
		return {
			formData: {},
			pageInfo: { pageNum: 1, pageSize: 10, total: 0 },
			operateList: [
				{
					name: "详情",
					fun: this.showDetail,
					isShow: () => {
						return true;
					}
				},
				{
					name: "编辑",
					fun: this.editFence,
					isShow: () => {
						return true;
					}
				},
				{
					name: "禁用",
					fun: this.switchStatus,
					isShow: row => {
						return row.status == 1;
					}
				},
				{
					name: "启用",
					fun: this.switchStatus,
					isShow: row => {
						return row.status == 2;
					}
				},
				{
					name: "删除",
					fun: this.delFence,
					isShow: () => {
						return true;
					}
				}
			],
			lists: []
		};
	},
	activated() {
		this.getList();
	},
	methods: {
		showDetail(row) {
			this.$refs.electric.openDrawer(row, "view");
		},
		oncheck() {
			this.pageInfo = { pageSize: 10, pageNum: 1, total: 0 };
			this.getList();
		},
		async switchStatus(row) {
			try {
				const url = "/crrc-palm-bus/electronicFence/enableOrDisable";
				if (row.status === 1) {
					await this.$confirm("确认禁用该电子围栏？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning"
					});
					await this.$http.post(url, { idList: [row.id], enableStatus: 2 }, { baseType: "base" });
				} else {
					await this.$http.post(url, { idList: [row.id], enableStatus: 1 }, { baseType: "base" });
				}
				this.getList();
			} catch (error) {
				console.log(error, "0-----状态切换报错");
			}
			console.log("切换状态", row.status);
		},
		editFence(row) {
			this.$refs.electric.openDrawer(row, "edit");
		},
		async delFence(row) {
			try {
				const url = "/crrc-palm-bus/electronicFence/remove";
				await this.$confirm("确认要删除该条电子围栏？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				});
				await this.$http.post(url, { id: parseInt(row.id) }, { baseType: "base", isRequestParam: false });
				this.$message({ message: "删除成功", type: "success" });
				this.getList();
			} catch (error) {
				console.log(error);
			}
		},
		resetForm() {
			this.pageInfo = { ...this.pageInfo, pageNum: 1 };
			this.formData = {};
			this.getList();
		},
		async getList() {
			console.log(this.formData, "----");
			const url = "/crrc-palm-bus/electronicFence/list";
			const res = await this.$http.post(
				url,
				{ ...this.formData, pageNum: this.pageInfo.pageNum, pageSize: this.pageInfo.pageSize, columns: ["create_time"] },
				{ baseType: "base", isRequestParam: false }
			);
			const { records, total, current } = res;
			this.lists = records;
			this.pageInfo = { ...this.pageIndex, total, pageNum: current };
		},
		handleSizeChange(val) {
			this.pageInfo = { ...this.pageInfo, pageSize: val };
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageInfo = { ...this.pageInfo, pageNum: val };
			this.getList();
		},
		createElectricFence() {
			this.$refs.electric?.openDrawer({}, "create");
		},
		addOrUpdate() {
			this.getList();
		}
	}
};
</script>
<style lang="scss" scoped></style>
