<template>
	<el-drawer :title="title" :visible.sync="visible" size="80%" :before-close="cancel">
		<div class="flex electric-detail">
			<el-form ref="form" :model="electricDetail" label-width="80px" :disabled="isView">
				<el-form-item label="围栏名称" prop="fenceName">
					<el-input v-model="electricDetail.fenceName" placeholder="请填写围栏名称" />
				</el-form-item>
				<el-form-item label="消息类型" prop="messageType">
					<el-radio-group v-model="electricDetail.messageType">
						<el-radio :label="item.value" v-for="item in messageTypeList" :key="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="生效状态" prop="status">
					<el-radio-group v-model="electricDetail.status">
						<el-radio :label="item.value" v-for="item in statusList" :key="`status-${item.value}`">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="所属机构" prop="orgName">
					<div @click="selectInstitutions">
						<el-input placeholder="请选择" readonly v-model="electricDetail.orgName">
							<el-button slot="append" icon="el-icon-circle-close" @click.stop="clear"></el-button>
						</el-input>
					</div>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="electricDetail.remark" type="textarea" placeholder="请填写备注" :maxlength="200" />
				</el-form-item>
				<el-form-item label="">
					<el-button @click="cancel">取消</el-button>
					<el-button type="primary" @click="saveData">保存</el-button>
				</el-form-item>
			</el-form>
			<div ref="container" class="flex-1 map-container"></div>
			<institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
		</div>
	</el-drawer>
</template>
<script>
import electricFenceMixin from "@/common/js/electricFence.mixin.js";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import * as maptalks from "maptalks";

export default {
	name: "electricFenceDetail",
	mixins: [electricFenceMixin],
	components: { institutionsTree },
	data() {
		return {
			title: "新增电子围栏",
			visible: false,
			electricDetail: { orgName: "" },
			diaform: {},
			serviceMap: null,
			isView: false
		};
	},
	methods: {
		openDrawer(electricFenceData, op) {
			console.log("electricFenceData", electricFenceData);
			switch (op) {
				case "create":
					this.title = "新增电子围栏";
					this.isView = false;
					break;
				case "view":
					this.title = "查看电子围栏";
					this.isView = true;
					this.electricDetail = { ...electricFenceData };
					break;
				case "edit":
					this.title = "编辑电子围栏";
					this.isView = false;
					this.electricDetail = { ...electricFenceData };
					break;
				default:
					break;
			}
			this.visible = true;
			this.initMap();
		},
		cancel() {
			this.visible = false;
			this.electricDetail = { orgName: "" };
		},
		async saveData() {
			const url = "/crrc-palm-bus/electronicFence/saveOrUpdate";
			const res = await this.$http.post(
				url,
				{ ...this.electricDetail, ...this.diaform },
				{ baseType: "base", isRequestParam: false }
			);
			this.electricDetail = { orgName: "" };
			this.visible = false;
			this.$emit("update");
			console.log(res, "更新或新增");
		},
		initMap() {
			const { electricDetail } = this;
			const { fenceData } = electricDetail;
			this.$nextTick(() => {
				if (this.serviceMap) {
					this.serviceMap.remove();
				}
				const dom = this.$refs.container;
				this.serviceMap = new maptalks.Map(dom, {
					center: [89.187441, 42.957145],
					zoom: 13,
					minZoom: 6,
					// maxZoom: 16,
					spatialReference: {
						projection: "baidu"
					},
					attribution: false,
					baseLayer: new maptalks.TileLayer("map", {
						urlTemplate: `//maponline{s}.bdimg.com/tile/?qt=vtile&x={x}&y={y}&z={z}&styles=pl&scaler=1&udt=${this.getCurrentDate}`,
						subdomains: [0, 1, 2, 3]
					})
				});
				// 限制地图区域
				const extent = new maptalks.Extent(70, 0, 130, 55);
				this.serviceMap.setMaxExtent(extent);
				const layer = new maptalks.VectorLayer("vector").addTo(this.serviceMap);
				const drawTool = new maptalks.DrawTool({
					mode: "Point"
				})
					.addTo(this.serviceMap)
					.disable();

				drawTool.on("drawend", param => {
					console.log(param.geometry, param);
					const { geometry } = param;
					const { _coordinates, _radius, _symbol } = geometry;
					this.electricDetail.fenceData = JSON.stringify({ _coordinates, _radius, _symbol });
					// 围栏数据组装
					layer.addGeometry(param.geometry);
				});

				const items = [
					{ label: "圆形", value: 1, command: "Circle" },
					{ label: "矩形", value: 2, command: "Rectangle" },
					{ label: "多边形", value: 3, command: "Polygon" }
				].map(item => {
					return {
						item: item.label,
						click: () => {
							layer.clear();
							this.electricDetail.fenceType = item.value;
							drawTool.setMode(item.command).enable();
						}
					};
				});

				if (!this.isView) {
					new maptalks.control.Toolbar({
						items: [
							{
								item: "绘制工具",
								children: items
							},
							{
								item: "禁用",
								click: function () {
									drawTool.disable();
								}
							},
							{
								item: "清除",
								click: function () {
									layer.clear();
								}
							}
						]
					}).addTo(this.serviceMap);
				}
				// 默认有围栏数据
				if (fenceData) {
					// 添加默认围栏数据
					const fenceDataObj = JSON.parse(fenceData);
					if (electricDetail.fenceType === 1) {
						// 圆形围栏
						const circle = new maptalks.Circle([fenceDataObj._coordinates.x, fenceDataObj._coordinates.y], fenceDataObj._radius, {
							editable: true,
							cursor: "pointer",
							draggable: true,
							symbol: {
								...fenceDataObj._symbol
							}
						});
						layer.addGeometry([circle]).addTo(this.serviceMap);
					} else {
						// 其他形状
						const coordinates = fenceDataObj._coordinates?.map(item => {
							return [item.x, item.y];
						});
						const polygon = new maptalks.Polygon([[...coordinates]], {
							editable: true,
							cursor: "pointer",
							draggable: true,
							symbol: {
								...fenceDataObj._symbol
							}
						});
						layer.addGeometry([polygon]).addTo(this.serviceMap);
					}
				}
			});
		},
		// 选择机构树
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 上级机构查询
		addInstitutions(data) {
			this.diaform.orgId = data.orgId;
			this.diaform.orgName = data.orgNa;
			this.electricDetail.orgName = data.orgNa;
		},
		clear() {
			this.diaform.orgId = "";
			this.diaform.orgName = "";
			this.electricDetail.orgName = "";
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep {
	.el-form {
		padding-right: 10px;
	}
	.el-drawer__header {
		font-size: 18px;
	}
	.el-drawer__body {
		padding: 0 12px;
		padding-bottom: 15px;
	}
}
.electric-detail {
	width: 100%;
	height: 100%;
}
</style>
